import React,{useState} from 'react';
// import { NumberFormat } from "react-number-format";
// import NumberFormat from "react-number-format";  

import {
  Label,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";


function Invest(){

  
const [state , setState] = useState({
  
  Duration: '', 
  totalReturning: '', 
  finalReturning: '', 
  extraReturning: ''
})

if(!sessionStorage.getItem('Piggyalphadev_capital')){
  sessionStorage.setItem('Piggyalphadev_capital', '5000');
  sessionStorage.setItem('Piggyalphadev_contribution', '100');
}else{

}

const [Capitals, setCapitals] = useState(Number(sessionStorage.getItem('Piggyalphadev_capital')));
const [Duration, setDuration] = useState(10);
const [interest, setInterest] = useState(0.1);
const [totalReturning, settotalReturning] = useState(0);
const [finalReturning, setfinalReturning] = useState(0);
const [monthlyContribution, setmonthlyContribution] = useState(Number(sessionStorage.getItem('Piggyalphadev_contribution')));

const handleEmploymentChange = (e) => { 
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}

const handleWizardClickPrev = (e) => {
  sessionStorage.setItem('Piggyalphadev_capital', Capitals);
  sessionStorage.setItem('Piggyalphadev_contribution', monthlyContribution);
  console.log(sessionStorage.getItem('Piggyalphadev_capital'));
  console.log(sessionStorage.getItem('Piggyalphadev_contribution'));
  window.location.reload(false);
};

// function SetResults(e){
 


const data = React.useMemo( 
  () => {
    const result = [];
    var capital = Number(sessionStorage.getItem('Piggyalphadev_capital'));
    var monthlyContri = Number(sessionStorage.getItem('Piggyalphadev_contribution'));
    console.log(capital);
    for (let i = 1; i <= Duration; i++) {
      let lastFutureValue = capital + monthlyContri;
      if (result.length > 0) {
        lastFutureValue = result[result.length - 1].value + monthlyContri;
      }
      result.push({
        label: `${i}`,
        value: lastFutureValue * Math.pow(1 + 0.1283, 1)
      });
    }
    return result;

  },
  [Capitals, Duration, monthlyContribution]
);
// }




function handleReturnsClick(e){
  e.preventDefault();
  const yellow = Number(Capitals) * (Math.pow((1 + 10 /100), Number(Duration)));
  console.log(yellow);
  console.log(Duration);
  console.log(totalReturning);
  console.log(finalReturning);
}



const handleEmploymentClick = (e) => {
  e.preventDefault();
  (Number(Capitals) * (Math.pow((1 + 0.05/12), (12 * Number(Duration)))) + Number(monthlyContribution) * ((Math.pow((1 + 0.05/12), (12 * 10))-1)/(0.05/12))).toFixed(2)

}
const returner = state.Capital+state.totalReturning;

const formattedValue = (
  Number(Capitals) * (Math.pow((1 + 0.1283/1), (1 * Number(Duration)))) + 
  Number(monthlyContribution) * (
    (Math.pow((1 + 0.1283/1), (1 * Number(Duration)))-1)/(0.1283/1)
  )
).toFixed(2);
const formattedString = '$' + formattedValue.toLocaleString();

const formattedValu = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format(formattedValue);





      return (
    <div>
        <header className="main-header position-absolute w-100">
                    <nav className="navbar navbar-expand-xl navbar-dark sticky-header">
                      <div className="container d-flex align-items-center justify-content-lg-between position-relative">
                        <a href="/" className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none">
                          <img src="assets/img/PAlogo.svg" alt="logo" style={{width: '220px'}} className="img-fluid logo-white" />
                          <img src="assets/img/logo-color.png" alt="logo" className="img-fluid logo-color" />
                        </a>
                        <a className="navbar-toggler position-absolute right-0 border-0 " href="#offcanvasWithBackdrop" role="button">
                          <span className="far fa-bars" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop" />
                        </a>
                        <div className="clearfix" />
                        <div className="collapse navbar-collapse justify-content-center">
                          <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                            <li className="nav-item dropdown">
                              <a className="nav-link" href="/" role="button" aria-expanded="false">
                                Home
                              </a>
                            </li>
                            <li><a href="about" className="nav-link">About</a></li>
                            <li><a href="invest" className="nav-link active activeguy">Invest</a></li>
                            <li><a href="blog" className="nav-link">Blog</a></li>
                          </ul>
                        </div>
                        <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
                          <a href="https://admin.alphawealth.io" className="btn btn-link text-decoration-none me-2">Sign In</a>
                          <a href="https://admin.alphawealth.io" className="btn btn-primary">Get Started</a>
                        </div>
                        <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasWithBackdrop">
                          <div className="offcanvas-header d-flex align-items-center mt-4">
                            <a href="/" className="d-flex align-items-center mb-md-0 text-decoration-none">
                              <img src="assets/img/logo-color.png" alt="logo" className="img-fluid ps-2" />
                            </a>
                            <button type="button" className="close-btn text-danger" data-bs-dismiss="offcanvas" aria-label="Close"><i className="far fa-close" /></button>
                          </div>
                          <div className="offcanvas-body">
                            <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                              <li className="nav-item dropdown">
                                <a className="nav-link " href="/" role="button" aria-expanded="false">
                                  Home
                                </a>
                              </li>
                              <li><a href="about" className="nav-link">about</a></li>
                              <li><a href="invest" className="nav-link">Invest</a></li>
                              <li><a href="blog" className="nav-link">Blog</a></li>
                              <li><a href="services" className="nav-link">Contact</a></li>
                            </ul>
                            <div className="action-btns mt-4 ps-3">
                              <a href="https://admin.alphawealth.io" className="btn btn-outline-primary me-2">Sign In</a>
                              <a href="https://admin.alphawealth.io" className="btn btn-primary">Get Started</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </nav>
                  </header>
        <section className="page-header overflow-hidden ptb-120 bg-dark" style={{background: 'url("assets/img/page-header-bg.svg")no-repeat bottom left'}}>
          <div className="container">
            <br/>
            <br/>
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <h1 className="display-5 fw-bold">Invest</h1>
                <p className="lead">We are dedicated to holding your hands all the way in making your investment decisions.</p>
              </div>
            </div>
            <div className="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5" />
          </div>
        </section>
        <div className="style-guide">
          <section className="feature-section ptb-120">
            <div className="container">
              <div className="row align-content-center justify-content-center">
                <div className="col-lg-6 col-md-12">
                  <div className="section-heading mb-5">
                    <h5 className="h6 text-primary">Features</h5>
                    <h2>Investing isn’t easy.<br /> We just make it feel that way.</h2>
                    <p>Investing with confidence doesn’t need to be complicated. Sit back and invest with managed portfolios and professional advice at your fingertips. </p>
                  </div>
                  <div className="single-feature d-flex mb-4">
                    <span className="fad fa-file-invoice-dollar fa-3x text-primary" />
                    <div className="ms-4 mt-2">
                      <h5>Manage your money on your terms</h5>
                      <p style={{textAlign: 'justify'}}>We help you choose a risk level that's right for you. Once you fund your account, we'll do all the heavy lifting so you can focus on what matters to you. </p>
                    </div>
                  </div>
                  <div className="single-feature d-flex mb-4">
                    <span className="fad fa-user-shield fa-2x text-primary" />
                    <div className="ms-4 mt-2">
                      <h5>CIPF protected</h5>
                      <p>Your accounts are held with our asset custodian, Fidelity clearing Canada, a subsidiary of Fidelity investments and are protected within specified limits in the event of its insolvency by The Canadian Investor Protection (TCIP).</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-8">
                  <div className="feature-shape-img position-relative mt-5 mt-lg-0">
                    <div className="img-bg-shape position-absolute">
                      {/* <img src="assets/img/integations/shape.svg" alt="integations" /> */}
                    </div>
                    <img src="assets/img/testimonial/Device-Macbook4.png" className="img-fluid skewed-img-right rounded-custom shadow-lg" alt="piggyalpha" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="style-guide">
          <section className="feature-section-two ptb-120 bg-light">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-6 col-md-12">
                  <div className="section-heading">
                    <h5 className="h6 text-primary">Portfolios</h5>
                    <h2>A portfolio for every goal </h2>
                    <p>We’ll help you find the right account type and you can choose a risk level you are comfortable with, then we build a custom portfolio based on your financial goals.</p>
                    <ul className="list-unstyled mt-5">
                      <li className="d-flex align-items-start mb-4">
                        <div className="icon-box bg-primary rounded me-4">
                          <i className="fas fa-bezier-curve text-white" />
                        </div>
                        <div className="icon-content">
                          <h3 className="h5">Alpha fund for growth investors</h3>
                          <p>This strategy has annualized 25% for the past 5 years.
                          </p>
                        </div>
                      </li>
                      <li className="d-flex align-items-start mb-4">
                        <div className="icon-box bg-success rounded me-4">
                          <i className="fas fa-fingerprint text-white" />
                        </div>
                        <div className="icon-content">
                          <h3 className="h5">Income fund for income oriented investors</h3>
                          <p>This strategy has competitive yield in a low interest rate environment, 2% quarterly yield in the past 5 years.</p>
                        </div>
                      </li>
                      <li className="d-flex align-items-start mb-4">
                        <div className="icon-box bg-warning  rounded me-4">
                          <i className="fas fa-fingerprint text-white" />
                        </div>
                        <div className="icon-content">
                          <h3 className="h5">Investment neutral fund</h3>
                          <p>This strategy is for the investor concerned about capital preservation. This strategy aims to track 100% of the S&amp;P 500 returns without the volatility.</p>
                        </div>
                      </li>
                    </ul>
                    <a href="https://admin.alphawealth.io" className="btn btn-primary">Get Started</a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-7">
                  <div className="feature-img-wrap position-relative d-flex flex-column align-items-end">
                    <img src="assets/img/Mockup2.png" alt="feature image" height={50} width={350} className="img-fluid rounded-custom" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="style-guide">
          <section className="feature-section  ptb-120">
            <div className="container">
              <div className="row align-items-center justify-content-between">
              <div className="col-lg-5 col-md-6">
                <div className="feature-content-wrap">
                    <h4 className=" text-dark">Calculate Investment Returns</h4>
                      <div className="row mt-4 register-form">
                        <div className="col-sm-6">
                          <label htmlFor="email" className="mb-1">Investment Capital</label>
                          <div className="input-group mb-3">
                            <div className="input-group-text">$</div>
                            <input type="number" className="form-control" value={Capitals}  onChange={e => setCapitals(e.target.value)}  placeholder="eg: 1000" required aria-label="number" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                        <label htmlFor="email" className="mb-1">Duration</label>
                          <div className="input-group mb-3">
                          <input type="number" className="form-control" value={Duration} onChange={e => setDuration(e.target.value)}  placeholder="eg: 1"  required aria-label="number" />
                          <div className="input-group-text">Year(s)</div>
                          </div>
                        </div>

                        <div className="col-sm-12">
                        <label htmlFor="email" className="mb-1">Annual Contribution</label>
                          <div className="input-group mb-3">
                          <div className="input-group-text">$</div>
                          <input type="number" className="form-control" value={monthlyContribution} onChange={e => setmonthlyContribution(e.target.value)}  placeholder="eg: 1"  required aria-label="number" />
                          </div>
                        </div>
                        <button href="https://admin.alphawealth.io" onClick={handleWizardClickPrev}  className="btn btn-primary">Get Results</button>
                        
                       
                        <div className="col-sm-12">
                        <label htmlFor="email" className="mb-1">Total Returns</label>
                          <div className="input-group mb-3">
                            <h2>
                              {formattedValu}
                                  </h2>
                          </div>

                                                 
                        </div>
                       
                      </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="image-wrap">
                  <div style={{ width: "100%", height: 300 }}>
                    <ResponsiveContainer>
                      <LineChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 0,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="label">
                          <Label value=""   position="insideBottom" />
                        </XAxis>
                        <YAxis />
                        <Line type="monotone" dataKey="value" />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                  <p>
                  In {Duration} years, you will have &nbsp;&nbsp; 
                   {/* <NumberFormat
                                  value= {(Number(Capitals) * (Math.pow((1 + 0.1283/1), (1 * Number(Duration)))) + Number(monthlyContribution) * ((Math.pow((1 + 0.1283/1), (1 * Number(Duration)))-1)/(0.1283/1))).toFixed(2)}
                                  displayType="text"
                                  thousandSeparator={true}
                                  prefix="$"
                                  /> */}
                                  {(formattedValu)}
                                  {/* {(Number(Capitals) * (Math.pow((1 + 0.1283/1), (1 * Number(Duration)))) + Number(monthlyContribution) * ((Math.pow((1 + 0.1283/1), (1 * Number(Duration)))-1)/(0.1283/1))).toFixed(2)} */}
                      </p> 

                      {/* <table>
                      <thead>
                        <tr>
                          <th scope="col">testing calculation</th>
                          <th scope="col">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map(({ label, value,lese }) => (
                          <tr key={label}>
                            <td>{label}</td>
                            <td>£{value}</td>
                            <td>{lese}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table> */}
                  
                    {/* <img src="assets/img/Device-Macbook.png" alt="feature img" className="img-fluid" /> */}
                  </div>
                </div>
               
              </div>
            </div>
          </section>
        </div>
        <div className="style-guide">
          <section className="feature-section ptb-120 bg-light">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 col-md-10">
                  <div className="section-heading text-center">
                    <h5 className="h6 text-primary">Account</h5>
                    <h2>Your Goals, Our Goals</h2>
                    <p>Not sure which accounts are right for you? We'll help you choose. We offer just about every account type available to Canadians. Looking for something specific? Let’s help you find one.</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="feature-grid mt-5">
                    <div className="feature-card border border-light border-2 bg-white rounded-custom p-5">
                      <div className="feature-icon rounded bg-success mb-32">
                        <i className="fal fa-file-chart-line fa-2x text-white" />
                      </div>
                      <div className="feature-content">
                        <h3 className="h5">TFSA (TAX-FREE SAVINGS ACCOUNT)</h3>
                        <p className="mb-0">Hold onto your earnings in your TFSA. Unlike non-registered accounts, you don’t pay any tax on the investment returns. All money made in a TFSA is tax-free. You can withdraw at any time without a penalty.</p>
                      </div>
                    </div>
                    <div className="feature-card border border-light border-2 bg-white rounded-custom p-5">
                      <div className="feature-icon rounded bg-danger mb-32">
                        <i className="fal fa-hand-holding-usd fa-2x text-white" />
                      </div>
                      <div className="feature-content">
                        <h3 className="h5">RRSP (REGISTERED RETIREMENT SAVINGS PLAN)</h3>
                        <p className="mb-0">Any income you earn in the RRSP is exempt from tax as long as the funds remain in the plan. </p>
                        <ul>
                          <li>Save for retirement goals.</li>
                          <li>Lower your taxes today.</li>
                          <li>Set up regular contributions.</li>
                          <li>Start sooner to save more.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="feature-card border border-light border-2 bg-white rounded-custom p-5">
                      <div className="feature-icon rounded bg-dark mb-32">
                        <i className="fal fa-user-friends fa-2x text-white" />
                      </div>
                      <div className="feature-content">
                        <h3 className="h5">SPOUSAL REGISTERED RETIREMENT SAVINGS PLAN (Spousal RRSP)</h3>
                        <p className="mb-0">Plan together and benefit. Lower your combined income taxes with two equally-sized RRSPs.</p>
                        <ul>
                          <li>Enjoy all the benefits of an RRSP.</li>
                          <li>Allow your spouse to contribute into your RRSP.</li>
                          <li>Income balancing at retirement.</li>
                          <li>Tax deduction for a higher income spouse.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="feature-card border border-light border-2 bg-white rounded-custom p-5">
                      <div className="feature-icon rounded bg-warning mb-32">
                        <i className="fal fa-graduation-cap fa-2x text-white" />
                      </div>
                      <div className="feature-content">
                        <h3 className="h5">REGISTERED EDUCATION SAVINGS PLAN</h3>
                        <p className="mb-0">With an RESP, parents can save for their children’s education after high school without any tax implication on investment and also get some Government contribution. </p>
                        <ul>
                          <li>Enjoy government contributions.</li>
                          <li>Tax-deferred growth.</li>
                          <li>Tax-sheltered growth.</li>
                          <li>Withdraw at a lower tax bracket.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="feature-card border border-light border-2 bg-white rounded-custom p-5">
                      <div className="feature-icon rounded bg-danger mb-32">
                        <i className="fal fa-network-wired fa-2x text-white" />
                      </div>
                      <div className="feature-content">
                        <h3 className="h5">Personal (Non-registered)</h3>
                        <p className="mb-0">With a personal account, you can put in any amount of money without any contribution limit.</p>
                      </div>
                    </div>
                    <div className="feature-card border border-light border-2 bg-white rounded-custom p-5">
                      <div className="feature-icon rounded bg-primary mb-32">
                        <i className="fal fa-layer-group fa-2x text-white" />
                      </div>
                      <div className="feature-content">
                        <h3 className="h5">LIRA</h3>
                        <p className="mb-0">With a LIRA, you can secure your pension with a former employer without being retired. </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <footer className="footer-section" id="footer">
          <div className="footer-top footer-light ptb-120">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-md-8 col-lg-5 mb-md-4 mb-lg-0">
                  <div className="footer-single-col">
                    <div className="footer-single-col mb-4">
                      <img src="assets/img/logo-white.png" alt="logo" className="img-fluid logo-white" />
                      <img src="assets/img/logo-color.png" alt="logo" className="img-fluid logo-color" />
                    </div>
                    <p>Our latest news, articles, and resources, we will sent to
                      your inbox weekly.</p>
                    <form className="newsletter-form position-relative d-block d-lg-flex d-md-flex">
                      <input type="text" className="input-newsletter form-control me-2" placeholder="Enter your email" name="email" required autoComplete="off" />
                      <input type="submit" defaultValue="Subscribe" data-wait="Please wait..." className="btn btn-primary mt-3 mt-lg-0 mt-md-0" />
                    </form>
                    <div className="ratting-wrap mt-4">
                      <h6 className="mb-0">10/10 Overall rating</h6>
                      <ul className="list-unstyled rating-list list-inline mb-0">
                        <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                        <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                        <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                        <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                        <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-5 mt-5 mt-md-0 mt-lg-0">
                  <div className="row">
                    <div className="col-md-4 col-lg-5 mt-4 mt-md-0 mt-lg-0">
                      <div className="footer-single-col">
                        <h3>Pages</h3>
                        <ul className="list-unstyled footer-nav-list mb-lg-0">
                          <li><a href="/" className="text-decoration-none">Home</a></li>
                          <li><a href="about" className="text-decoration-none">About</a></li>
                          <li><a href="invest" className="text-decoration-none">Invest</a></li>
                          <li><a href="blog" className="text-decoration-none">Blog</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-5 mt-5 mt-md-0 mt-lg-0">
                      <div className="footer-single-col">
                        <h3>Legal</h3>
                        <ul className="list-unstyled footer-nav-list mb-lg-0">
                          <li><a href="terms" className="text-decoration-none">Terms of Service</a></li>
                          <li><a href="privacy" className="text-decoration-none">Privacy Policy</a></li>
                         
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom footer-light py-4">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-md-7 col-lg-7">
                  <div className="copyright-text">
                    <p className="mb-lg-0 mb-md-0">2021 PiggyAlpha. </p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="footer-single-col text-start text-lg-end text-md-end">
                    <ul className="list-unstyled list-inline footer-social-list mb-0">
                      <li className="list-inline-item"><a href="https://facebook.com/piggyalpha"><i className="fab fa-facebook-f" /></a></li>
                      <li className="list-inline-item"><a href="https://instagram.com/piggyalpha"><i className="fab fa-instagram" /></a></li>
                      <li className="list-inline-item"><a href="https://google.com/piggyalpha"><i className="fab fa-google" /></a></li>
                      <li className="list-inline-item"><a href="https://twitter.com/piggyalpha"><i className="fab fa-twitter" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
                </div>
            
      );
    // }
  };

export default Invest;

