import React from 'react';


class About extends React.Component{
    render () {
      return (
                <div>
        <header className="main-header position-absolute w-100">
                    <nav className="navbar navbar-expand-xl navbar-dark sticky-header">
                      <div className="container d-flex align-items-center justify-content-lg-between position-relative">
                        <a href="/" className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none">
                          <img src="assets/img/PAlogo.svg" alt="logo" style={{width: '220px'}} className="img-fluid logo-white" />
                          <img src="assets/img/logo-color.png" alt="logo" className="img-fluid logo-color" />
                        </a>
                        <a className="navbar-toggler position-absolute right-0 border-0 " href="#offcanvasWithBackdrop" role="button">
                          <span className="far fa-bars" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop" />
                        </a>
                        <div className="clearfix" />
                        <div className="collapse navbar-collapse justify-content-center">
                          <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                            <li className="nav-item dropdown">
                              <a className="nav-link" href="/" role="button" aria-expanded="false">
                                Home
                              </a>
                            </li>
                            <li><a href="about" className="nav-link active activeguy">About</a></li>
                            <li><a href="Invest" className="nav-link">Invest</a></li>
                            <li><a href="blog" className="nav-link">Blog</a></li>
                          </ul>
                        </div>
                        <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
                          <a href="https://admin.alphawealth.io" className="btn btn-link text-decoration-none me-2">Sign In</a>
                          <a href="https://admin.alphawealth.io" className="btn btn-primary">Get Started</a>
                        </div>
                        <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasWithBackdrop">
                          <div className="offcanvas-header d-flex align-items-center mt-4">
                            <a href="/" className="d-flex align-items-center mb-md-0 text-decoration-none">
                              <img src="assets/img/logo-color.png" alt="logo" className="img-fluid ps-2" />
                            </a>
                            <button type="button" className="close-btn text-danger" data-bs-dismiss="offcanvas" aria-label="Close"><i className="far fa-close" /></button>
                          </div>
                          <div className="offcanvas-body">
                            <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                              <li className="nav-item dropdown">
                                <a className="nav-link " href="/" role="button" aria-expanded="false">
                                  Home
                                </a>
                              </li>
                              <li><a href="about" className="nav-link">about</a></li>
                              <li><a href="invest" className="nav-link">Invest</a></li>
                              <li><a href="blog" className="nav-link">Blog</a></li>
                              <li><a href="services" className="nav-link">Contact</a></li>
                            </ul>
                            <div className="action-btns mt-4 ps-3">
                              <a href="https://admin.alphawealth.io" className="btn btn-outline-primary me-2">Sign In</a>
                              <a href="https://admin.alphawealth.io" className="btn btn-primary">Get Started</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </nav>
                  </header>
                  <section className="page-header  overflow-hidden ptb-120 bg-dark" style={{background: 'url("assets/img/page-header-bg.svg")no-repeat bottom left'}}>
                    <div className="container">
                      <br/>
                      <br/>
                      <div className="row">
                        <div className="col-lg-8 col-md-12">
                          <h1 className="display-5 fw-bold">About us</h1>
                          <p className="lead">We provide sustainable financial solutions that will empower our clients to begin their independent financial journey.</p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="feature-section ptb-120">
                    <div className="container">
                      <div className="row align-items-lg-center justify-content-between">
                        <div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
                          <div className="mb-4">
                            <h2>Your goals are our goals. </h2>
                            <p>Traditional solutions charge a bomb. It's why PiggyAlpha keeps things simple. We give you a solution that's much cheaper and better than conventional platforms. It's free to sign up, and to withdraw your money, any time.</p>
                          </div>
                          <ul className="list-unstyled d-flex flex-wrap list-two-col mt-5">
                            <li>
                              <span><i className="fal fa-user fa-2x text-primary mb-4" /></span>
                              <h3 className="h5">Customer analysis</h3>
                              <p>We provide sustainable financial solutions that will empower our clients to begin their independent financial journey.</p>
                            </li>
                            <li>
                              <span><i className="fal fa-headset fa-2x text-primary mb-4" /></span>
                              <h3 className="h5">Support</h3>
                              <p>Our expert financial advisors are always available when you need them backed with a dedicated support team.</p>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 order-lg-1">
                          <div className="pr-lg-4 mt-md-4 position-relative">
                            <div className="bg-light text-center rounded-custom overflow-hidden p-lg-5 p-4 mx-lg-auto">
                              <img src="assets/img/testimonial/Device-Macbook.png" alt="" className="img-fluid shadow-sm rounded-custom" />
                              <div className="position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="feature-section pt-60 pb-120">
                    <div className="container">
                      <div className="row align-items-lg-center justify-content-between">
                        <div className="col-lg-5 order-lg-1 mb-7 mb-lg-0">
                          <div className="mb-4">
                            <h2>Who we are</h2>
                            <p style={{textAlign: 'justify'}}>Our Investment Advisory Committee are recognized thought leaders in the investment community. Our portfolio manager, Jean Charbonneau has managed $10bn+ in assets and has been a manger for more than three decades. </p>
                          </div>
                          <ul className="list-unstyled d-flex flex-wrap list-two-col mt-5">
                            <li>
                              <span><i className="fal fa-users fa-2x text-primary mb-4" /></span>
                              <h3 className="h5">Customers first</h3>
                              <p>Every decision we make is centred around our customers' needs and convenience.</p>
                            </li>
                            <li>
                              <span><i className="fal fa-business-time fa-2x text-primary mb-4" /></span>
                              <h3 className="h5">Business Integrity</h3>
                              <p>We want our customers to be completely aware of everything to do with their investments. Whatever we do is always crystal clear.</p>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-6 order-lg-2">
                          <div className="pr-lg-4 position-relative">
                            <div className="bg-light text-center rounded-custom overflow-hidden p-lg-5 p-4 mx-lg-auto">
                              <img src="assets/img/testimonial/Device-Macbook2.png" alt="" className="img-fluid rounded-custom shadow-sm" />
                              <div className="position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 bottom--40 right--40 bottom-right" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="related-job-list ptb-120 bg-light">
                    <div className="container">
                      <div className="row align-items-center justify-content-between">
                        <div className="col-lg-6 col-md-12">
                          <div className="section-heading">
                            <h2 className=" text-dark">Why people invest with PiggyAlpha</h2>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <p>Our technology is always a curve ahead. Our operations, systems and infrastructure are reviewed constantly to ensure that they are resilient and reliable. </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 col-md-6 checkoout">
                          <a className="text-decoration-none  mt-4 mt-lg-0 mt-xl-0 single-open-job p-5 bg-white d-block rounded-custom feature-card border border-light border-2">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                            </div>
                            <h3 className="h5 text-dark"> <i className="far fa-graduation-cap me-2" /> Education</h3>
                            <ul className="job-info-list list-inline list-unstyled text-muted">
                              <li className="list-inline-item"> "I am looking to build long term wealth.”</li>
                              <br /><br />
                              <center><li className="list-inline-item bg-primary-soft"> 100% Alpha Strategy.</li></center>
                            </ul>
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <a  className="text-decoration-none mt-4 mt-lg-0 mt-xl-0 single-open-job p-5 bg-white d-block rounded-custom feature-card border border-light border-2">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                            </div>
                            <h3 className="h5 text-dark"> <i className="far fa-hand-holding-usd me-2" /> Retirement</h3>
                            <ul className="job-info-list list-inline list-unstyled text-muted">
                              <li className="list-inline-item"> “I am planning to have my money provide me with income.”</li>
                              <br /><br />
                              <center><li className="list-inline-item bg-primary-soft"> 100% Income Strategy.</li></center>
                            </ul>
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <a  className="text-decoration-none mt-4 mt-lg-0 mt-xl-0 single-open-job p-5 bg-white d-block rounded-custom feature-card border border-light border-2">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                            </div>
                            <h3 className="h5 text-dark"> <i className="far fa-user-shield me-2" /> Safety Net</h3>
                            <ul className="job-info-list list-inline list-unstyled text-muted">
                              <li className="list-inline-item"> “I am looking to have backup funds that I can access whenever.”</li>
                              <br /><br />
                              <center><li className="list-inline-item bg-primary-soft"> 100% Neutral Strategy.</li></center>
                            </ul>
                          </a>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <a className="text-decoration-none mt-4 mt-lg-0 mt-xl-0 single-open-job p-5 bg-white d-block rounded-custom feature-card border border-light border-2">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                            </div>
                            <h3 className="h5 text-dark"> <i className="far fa-shopping-bag me-2" /> Major Purchases</h3>
                            <ul className="job-info-list list-inline list-unstyled text-muted">
                              <li className="list-inline-item"> “I am planning to buy a house in a few years.”</li>
                              <br /><br />
                              <center><li className="list-inline-item bg-primary-soft">30% Neutral & 70% Alpha Strategy.</li></center>
                            </ul>
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <a  className="text-decoration-none mt-4 mt-lg-0 mt-xl-0 single-open-job p-5 bg-white d-block rounded-custom feature-card border border-light border-2">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                            </div>
                            <h3 className="h5 text-dark"> <i className="far fa-money-bill-wave me-2" /> Trust</h3>
                            <ul className="job-info-list list-inline list-unstyled text-muted">
                              <li className="list-inline-item"> “I am looking to build long term wealth.”</li>
                              <br /><br />
                              <center><li className="list-inline-item bg-primary-soft"> 100% Alpha Strategy.</li></center>
                            </ul>
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <a className="text-decoration-none mt-4 mt-lg-0 mt-xl-0 single-open-job p-5 bg-white d-block rounded-custom feature-card border border-light border-2">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                            </div>
                            <h6 className="h5 text-dark"> <i className="far fa-money-bill-wave me-2" /> Socially Aware</h6>
                            <ul className="job-info-list list-inline list-unstyled text-muted">
                              <li className="list-inline-item"> “I am looking to invest in companies that are socially and environmentally cognitive.”</li>
                              <center><li className="list-inline-item bg-primary-soft"> 100% ESG Strategy.</li></center>
                            </ul>
                          </a>
                        </div>
                      </div>

                    </div>
                  </section>
                  <section className="sign-up-in-section bg-dark ptb-120 position-relative overflow-hidden">
                    <div className="container">
                      <div className="row align-items-center justify-content-between">
                        <div className="col-xl-5 col-lg-5 col-md-12 order-1 order-lg-0">
                          <div className="testimonial-tab-slider-wrap mt-5 mt-lg-0 mt-xl-0">
                            <h2 className="text-white">Reach out to us</h2>
                            <hr />
                            <div className="tab-content testimonial-tab-content text-white-80" id="pills-tabContent">
                              <div className="tab-pane fade show active" id="testimonial-tab-1" role="tabpanel">
                                <p style={{textAlign: 'justify'}}>Piggy Alpha is on a mission to help you achieve your financial goals by making it easy to save more, spend less and invest smarter. Top performing portfolios, matched to your style. We empower you with simple digital solutions to help you take control of your money. We’re helping even more Canadian millennials build real wealth.</p>
                                <p style={{textAlign: 'justify'}}>Fill the form on the right and have our clent support team contact you concerning your needs.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1">
                          <div className="register-wrap p-5 bg-white shadow rounded-custom">
                            <h3 className="fw-medium">Fill out the form and we'll be in touch as soon as possible.</h3>
                            <form action="#" className="mt-4 register-form">
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="Name" aria-label="name" />
                                  </div>
                                </div>
                                <div className="col-sm-6 ">
                                  <div className="input-group mb-3">
                                    <input type="email" className="form-control" placeholder="Email" aria-label="email" />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="Phone number" aria-label="Phone" />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="input-group mb-3">
                                    <input type="email" className="form-control" placeholder="Work email" aria-label="work-Email" />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="input-group mb-3">
                                    <select className="form-control form-select" name="budget" id="budget" required data-msg="Please select your budget.">
                                      <option value selected disabled>Investment</option>
                                      <option value="budget1">None, just getting started</option>
                                      <option value="budget1">Less than $20,000</option>
                                      <option value="budget1">$20,000 to $50,000</option>
                                      <option value="budget1">$50,000 to $100,000</option>
                                      <option value="budget2">$100,000 to $500,000</option>
                                      <option value="budget3">More than $500,000</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="input-group mb-3">
                                    <select className="form-control form-select" name="country" id="country" required data-msg="Please select your country.">
                                      <option value selected disabled>Country</option>
                                      <option value="AF">Afghanistan</option>
                                      <option value="AX">Åland Islands</option>
                                      <option value="AL">Albania</option>
                                      <option value="DZ">Algeria</option>
                                      <option value="AS">American Samoa</option>
                                      <option value="AD">Andorra</option>
                                      <option value="AO">Angola</option>
                                      <option value="AI">Anguilla</option>
                                      <option value="AQ">Antarctica</option>
                                      <option value="AG">Antigua and Barbuda</option>
                                      <option value="AR">Argentina</option>
                                      <option value="AM">Armenia</option>
                                      <option value="AW">Aruba</option>
                                      <option value="AU">Australia</option>
                                      <option value="AT">Austria</option>
                                      <option value="AZ">Azerbaijan</option>
                                      <option value="BS">Bahamas</option>
                                      <option value="BH">Bahrain</option>
                                      <option value="BD">Bangladesh</option>
                                      <option value="BB">Barbados</option>
                                      <option value="BY">Belarus</option>
                                      <option value="BE">Belgium</option>
                                      <option value="BZ">Belize</option>
                                      <option value="BJ">Benin</option>
                                      <option value="BM">Bermuda</option>
                                      <option value="BT">Bhutan</option>
                                      <option value="BO">Bolivia, Plurinational State of</option>
                                      <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                      <option value="BA">Bosnia and Herzegovina</option>
                                      <option value="BW">Botswana</option>
                                      <option value="BV">Bouvet Island</option>
                                      <option value="BR">Brazil</option>
                                      <option value="IO">British Indian Ocean Territory</option>
                                      <option value="BN">Brunei Darussalam</option>
                                      <option value="BG">Bulgaria</option>
                                      <option value="BF">Burkina Faso</option>
                                      <option value="BI">Burundi</option>
                                      <option value="KH">Cambodia</option>
                                      <option value="CM">Cameroon</option>
                                      <option value="CA">Canada</option>
                                      <option value="CV">Cape Verde</option>
                                      <option value="KY">Cayman Islands</option>
                                      <option value="CF">Central African Republic</option>
                                      <option value="TD">Chad</option>
                                      <option value="CL">Chile</option>
                                      <option value="CN">China</option>
                                      <option value="CX">Christmas Island</option>
                                      <option value="CC">Cocos (Keeling) Islands</option>
                                      <option value="CO">Colombia</option>
                                      <option value="KM">Comoros</option>
                                      <option value="CG">Congo</option>
                                      <option value="CD">Congo, the Democratic Republic of the</option>
                                      <option value="CK">Cook Islands</option>
                                      <option value="CR">Costa Rica</option>
                                      <option value="CI">Côte d'Ivoire</option>
                                      <option value="HR">Croatia</option>
                                      <option value="CU">Cuba</option>
                                      <option value="CW">Curaçao</option>
                                      <option value="CY">Cyprus</option>
                                      <option value="CZ">Czech Republic</option>
                                      <option value="DK">Denmark</option>
                                      <option value="DJ">Djibouti</option>
                                      <option value="DM">Dominica</option>
                                      <option value="DO">Dominican Republic</option>
                                      <option value="EC">Ecuador</option>
                                      <option value="EG">Egypt</option>
                                      <option value="SV">El Salvador</option>
                                      <option value="GQ">Equatorial Guinea</option>
                                      <option value="ER">Eritrea</option>
                                      <option value="EE">Estonia</option>
                                      <option value="ET">Ethiopia</option>
                                      <option value="FK">Falkland Islands (Malvinas)</option>
                                      <option value="FO">Faroe Islands</option>
                                      <option value="FJ">Fiji</option>
                                      <option value="FI">Finland</option>
                                      <option value="FR">France</option>
                                      <option value="GF">French Guiana</option>
                                      <option value="PF">French Polynesia</option>
                                      <option value="TF">French Southern Territories</option>
                                      <option value="GA">Gabon</option>
                                      <option value="GM">Gambia</option>
                                      <option value="GE">Georgia</option>
                                      <option value="DE">Germany</option>
                                      <option value="GH">Ghana</option>
                                      <option value="GI">Gibraltar</option>
                                      <option value="GR">Greece</option>
                                      <option value="GL">Greenland</option>
                                      <option value="GD">Grenada</option>
                                      <option value="GP">Guadeloupe</option>
                                      <option value="GU">Guam</option>
                                      <option value="GT">Guatemala</option>
                                      <option value="GG">Guernsey</option>
                                      <option value="GN">Guinea</option>
                                      <option value="GW">Guinea-Bissau</option>
                                      <option value="GY">Guyana</option>
                                      <option value="HT">Haiti</option>
                                      <option value="HM">Heard Island and McDonald Islands</option>
                                      <option value="VA">Holy See (Vatican City State)</option>
                                      <option value="HN">Honduras</option>
                                      <option value="HK">Hong Kong</option>
                                      <option value="HU">Hungary</option>
                                      <option value="IS">Iceland</option>
                                      <option value="IN">India</option>
                                      <option value="ID">Indonesia</option>
                                      <option value="IR">Iran, Islamic Republic of</option>
                                      <option value="IQ">Iraq</option>
                                      <option value="IE">Ireland</option>
                                      <option value="IM">Isle of Man</option>
                                      <option value="IL">Israel</option>
                                      <option value="IT">Italy</option>
                                      <option value="JM">Jamaica</option>
                                      <option value="JP">Japan</option>
                                      <option value="JE">Jersey</option>
                                      <option value="JO">Jordan</option>
                                      <option value="KZ">Kazakhstan</option>
                                      <option value="KE">Kenya</option>
                                      <option value="KI">Kiribati</option>
                                      <option value="KP">Korea, Democratic People's Republic of</option>
                                      <option value="KR">Korea, Republic of</option>
                                      <option value="KW">Kuwait</option>
                                      <option value="KG">Kyrgyzstan</option>
                                      <option value="LA">Lao People's Democratic Republic</option>
                                      <option value="LV">Latvia</option>
                                      <option value="LB">Lebanon</option>
                                      <option value="LS">Lesotho</option>
                                      <option value="LR">Liberia</option>
                                      <option value="LY">Libya</option>
                                      <option value="LI">Liechtenstein</option>
                                      <option value="LT">Lithuania</option>
                                      <option value="LU">Luxembourg</option>
                                      <option value="MO">Macao</option>
                                      <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                                      <option value="MG">Madagascar</option>
                                      <option value="MW">Malawi</option>
                                      <option value="MY">Malaysia</option>
                                      <option value="MV">Maldives</option>
                                      <option value="ML">Mali</option>
                                      <option value="MT">Malta</option>
                                      <option value="MH">Marshall Islands</option>
                                      <option value="MQ">Martinique</option>
                                      <option value="MR">Mauritania</option>
                                      <option value="MU">Mauritius</option>
                                      <option value="YT">Mayotte</option>
                                      <option value="MX">Mexico</option>
                                      <option value="FM">Micronesia, Federated States of</option>
                                      <option value="MD">Moldova, Republic of</option>
                                      <option value="MC">Monaco</option>
                                      <option value="MN">Mongolia</option>
                                      <option value="ME">Montenegro</option>
                                      <option value="MS">Montserrat</option>
                                      <option value="MA">Morocco</option>
                                      <option value="MZ">Mozambique</option>
                                      <option value="MM">Myanmar</option>
                                      <option value="NA">Namibia</option>
                                      <option value="NR">Nauru</option>
                                      <option value="NP">Nepal</option>
                                      <option value="NL">Netherlands</option>
                                      <option value="NC">New Caledonia</option>
                                      <option value="NZ">New Zealand</option>
                                      <option value="NI">Nicaragua</option>
                                      <option value="NE">Niger</option>
                                      <option value="NG">Nigeria</option>
                                      <option value="NU">Niue</option>
                                      <option value="NF">Norfolk Island</option>
                                      <option value="MP">Northern Mariana Islands</option>
                                      <option value="NO">Norway</option>
                                      <option value="OM">Oman</option>
                                      <option value="PK">Pakistan</option>
                                      <option value="PW">Palau</option>
                                      <option value="PS">Palestinian Territory, Occupied</option>
                                      <option value="PA">Panama</option>
                                      <option value="PG">Papua New Guinea</option>
                                      <option value="PY">Paraguay</option>
                                      <option value="PE">Peru</option>
                                      <option value="PH">Philippines</option>
                                      <option value="PN">Pitcairn</option>
                                      <option value="PL">Poland</option>
                                      <option value="PT">Portugal</option>
                                      <option value="PR">Puerto Rico</option>
                                      <option value="QA">Qatar</option>
                                      <option value="RE">Réunion</option>
                                      <option value="RO">Romania</option>
                                      <option value="RU">Russian Federation</option>
                                      <option value="RW">Rwanda</option>
                                      <option value="BL">Saint Barthélemy</option>
                                      <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                                      <option value="KN">Saint Kitts and Nevis</option>
                                      <option value="LC">Saint Lucia</option>
                                      <option value="MF">Saint Martin (French part)</option>
                                      <option value="PM">Saint Pierre and Miquelon</option>
                                      <option value="VC">Saint Vincent and the Grenadines</option>
                                      <option value="WS">Samoa</option>
                                      <option value="SM">San Marino</option>
                                      <option value="ST">Sao Tome and Principe</option>
                                      <option value="SA">Saudi Arabia</option>
                                      <option value="SN">Senegal</option>
                                      <option value="RS">Serbia</option>
                                      <option value="SC">Seychelles</option>
                                      <option value="SL">Sierra Leone</option>
                                      <option value="SG">Singapore</option>
                                      <option value="SX">Sint Maarten (Dutch part)</option>
                                      <option value="SK">Slovakia</option>
                                      <option value="SI">Slovenia</option>
                                      <option value="SB">Solomon Islands</option>
                                      <option value="SO">Somalia</option>
                                      <option value="ZA">South Africa</option>
                                      <option value="GS">South Georgia and the South Sandwich Islands</option>
                                      <option value="SS">South Sudan</option>
                                      <option value="ES">Spain</option>
                                      <option value="LK">Sri Lanka</option>
                                      <option value="SD">Sudan</option>
                                      <option value="SR">Suriname</option>
                                      <option value="SJ">Svalbard and Jan Mayen</option>
                                      <option value="SZ">Swaziland</option>
                                      <option value="SE">Sweden</option>
                                      <option value="CH">Switzerland</option>
                                      <option value="SY">Syrian Arab Republic</option>
                                      <option value="TW">Taiwan, Province of China</option>
                                      <option value="TJ">Tajikistan</option>
                                      <option value="TZ">Tanzania, United Republic of</option>
                                      <option value="TH">Thailand</option>
                                      <option value="TL">Timor-Leste</option>
                                      <option value="TG">Togo</option>
                                      <option value="TK">Tokelau</option>
                                      <option value="TO">Tonga</option>
                                      <option value="TT">Trinidad and Tobago</option>
                                      <option value="TN">Tunisia</option>
                                      <option value="TR">Turkey</option>
                                      <option value="TM">Turkmenistan</option>
                                      <option value="TC">Turks and Caicos Islands</option>
                                      <option value="TV">Tuvalu</option>
                                      <option value="UG">Uganda</option>
                                      <option value="UA">Ukraine</option>
                                      <option value="AE">United Arab Emirates</option>
                                      <option value="GB">United Kingdom</option>
                                      <option value="US">United States</option>
                                      <option value="UM">United States Minor Outlying Islands</option>
                                      <option value="UY">Uruguay</option>
                                      <option value="UZ">Uzbekistan</option>
                                      <option value="VU">Vanuatu</option>
                                      <option value="VE">Venezuela, Bolivarian Republic of</option>
                                      <option value="VN">Viet Nam</option>
                                      <option value="VG">Virgin Islands, British</option>
                                      <option value="VI">Virgin Islands, U.S.</option>
                                      <option value="WF">Wallis and Futuna</option>
                                      <option value="EH">Western Sahara</option>
                                      <option value="YE">Yemen</option>
                                      <option value="ZM">Zambia</option>
                                      <option value="ZW">Zimbabwe</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="input-group mb-3">
                                    <textarea className="form-control" placeholder="Tell us more about your requests and inquiries." style={{height: '120px'}} defaultValue={""} />
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckChecked" />
                                    <label className="form-check-label small" htmlFor="flexCheckChecked">
                                      Yes, I'd like to receive occasional marketing emails from us. I have the
                                      right to opt out at any time.
                                      <a href="privacy"> View privacy policy</a>.
                                    </label>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <button type="submit" className="btn btn-primary mt-4 d-block w-100">Contact Us
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <footer className="footer-section">
                    <div className="footer-top footer-light ptb-120">
                      <div className="container">
                        <div className="row justify-content-between">
                          <div className="col-md-8 col-lg-5 mb-md-4 mb-lg-0">
                            <div className="footer-single-col">
                              <div className="footer-single-col mb-4">
                                <img src="assets/img/logo-white.png" alt="logo" className="img-fluid logo-white" />
                                <img src="assets/img/logo-color.png" alt="logo" className="img-fluid logo-color" />
                              </div>
                              <p>Our latest news, articles, and resources, we will sent to
                                your inbox weekly.</p>
                              <form className="newsletter-form position-relative d-block d-lg-flex d-md-flex">
                                <input type="text" className="input-newsletter form-control me-2" placeholder="Enter your email" name="email" required autoComplete="off" />
                                <input type="submit" defaultValue="Subscribe" data-wait="Please wait..." className="btn btn-primary mt-3 mt-lg-0 mt-md-0" />
                              </form>
                              <div className="ratting-wrap mt-4">
                                <h6 className="mb-0">10/10 Overall rating</h6>
                                <ul className="list-unstyled rating-list list-inline mb-0">
                                  <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                                  <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                                  <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                                  <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                                  <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-5 mt-5 mt-md-0 mt-lg-0">
                            <div className="row">
                              <div className="col-md-4 col-lg-5 mt-4 mt-md-0 mt-lg-0">
                                <div className="footer-single-col">
                                  <h3>Pages</h3>
                                  <ul className="list-unstyled footer-nav-list mb-lg-0">
                                    <li><a href="/" className="text-decoration-none">Home</a></li>
                                    <li><a href="About" className="text-decoration-none">About</a></li>
                                    <li><a href="services" className="text-decoration-none">Invest</a></li>
                                    <li><a href="blog" className="text-decoration-none">Blog</a></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-md-4 col-lg-5 mt-5 mt-md-0 mt-lg-0">
                                <div className="footer-single-col">
                                  <h3>Legal</h3>
                                  <ul className="list-unstyled footer-nav-list mb-lg-0">
                                    <li><a href="terms" className="text-decoration-none">Terms of Service</a></li>
                                    <li><a href="privacy" className="text-decoration-none">Privacy Policy</a></li>
                                  
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="footer-bottom footer-light py-4">
                      <div className="container">
                        <div className="row justify-content-between align-items-center">
                          <div className="col-md-7 col-lg-7">
                            <div className="copyright-text">
                              <p className="mb-lg-0 mb-md-0">2021 PiggyAlpha.</p>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-4">
                            <div className="footer-single-col text-start text-lg-end text-md-end">
                              <ul className="list-unstyled list-inline footer-social-list mb-0">
                                <li className="list-inline-item"><a href="#"><i className="fab fa-facebook-f" /></a></li>
                                <li className="list-inline-item"><a href="#"><i className="fab fa-instagram" /></a></li>
                                <li className="list-inline-item"><a href="#"><i className="fab fa-twitter" /></a></li>
                                <li className="list-inline-item"><a href="#"><i className="fab fa-linkedin" /></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </footer>
                </div>
            
      );
    }
  };

export default About;
