import React from 'react';


class Terms extends React.Component{
    render () {
      return (
      <div>
        <div className="main-wrapper">
          <header className="main-header position-absolute w-100">
            <nav className="navbar navbar-expand-xl navbar-dark sticky-header">
              <div className="container d-flex align-items-center justify-content-lg-between position-relative">
                <a href="/" className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none">
                  Piggy Alpha
                  <img src="assets/img/logo-color.png" alt="logo" className="img-fluid logo-color" />
                </a>
                <a className="navbar-toggler position-absolute right-0 border-0 " href="#offcanvasWithBackdrop" role="button">
                  <span className="far fa-bars" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop" />
                </a>
                <div className="clearfix" />
                <div className="collapse navbar-collapse justify-content-center">
                  <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                    <li className="nav-item dropdown">
                      <a className="nav-link active" href="/" role="button" aria-expanded="false">
                        Home
                      </a>
                    </li>
                    <li><a href="about" className="nav-link">About</a></li>
                    <li><a href="invest" className="nav-link">Invest</a></li>
                    <li><a href="blog" className="nav-link">Blog</a></li>
                  </ul>
                </div>
                <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
                  <a href="https://admin.alphawealth.io" className="btn btn-link text-decoration-none me-2">Sign In</a>
                  <a href="https://admin.alphawealth.io" className="btn btn-primary">Get Started</a>
                </div>
                <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasWithBackdrop">
                  <div className="offcanvas-header d-flex align-items-center mt-4">
                    <a href="/" className="d-flex align-items-center mb-md-0 text-decoration-none">
                      <img src="assets/img/logo-color.png" alt="logo" className="img-fluid ps-2" />
                    </a>
                    <button type="button" className="close-btn text-danger" data-bs-dismiss="offcanvas" aria-label="Close"><i className="far fa-close" /></button>
                  </div>
                  <div className="offcanvas-body">
                    <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                      <li className="nav-item dropdown">
                        <a className="nav-link " href="0#" role="button" aria-expanded="false">
                          Home
                        </a>
                      </li>
                      <li><a href="about" className="nav-link">about</a></li>
                      <li><a href="invest" className="nav-link">Invest</a></li>
                      <li><a href="blog" className="nav-link">Blog</a></li>
                      <li><a href="services" className="nav-link">Contact</a></li>
                    </ul>
                    <div className="action-btns mt-4 ps-3">
                      <a href="https://admin.alphawealth.io" className="btn btn-outline-primary me-2">Sign In</a>
                      <a href="https://admin.alphawealth.io" className="btn btn-primary">Get Started</a>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </header>
          <section className="page-header overflow-hidden ptb-120 bg-dark" style={{background: 'url("assets/img/page-header-bg.svg")no-repeat bottom left'}}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 col-md-12">
                  <div className="text-center">
                    <h1 className="display-5 fw-bold">Terms Of Use</h1>
                    <div className="form-block-banner mw-60 m-auto mt-5">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="support-content ptb-120">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-12 col-md-8">
                  <div className="tab-content" id="v-pills-supportContent">
                    <div className="tab-pane fade show active" id="support-tab-1" role="tabpanel">
                      <div className="support-article-wrap" style={{textAlign: 'justify'}}>
                        <h4>Piggy Alpha Terms of Use (Investment Advisory)</h4>
                        <p>Policy Effective Date: November 22nd, 2021</p>
                        <ul className="support-article-list list-unstyled mt-4">
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">1. Introduction</h3>
                              <p style={{textAlign: 'justify'}}>
                                PiggyAlpha Inc. ("PiggyAlpha," an SEC-registered adviser) is an automated investment adviser available via our Web App and through mobile applications.
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">1.1 Purpose</h3>
                              <p style={{textAlign: 'justify'}}>
                                Piggy Alpha is on a mission to help you achieve your financial goals by making it easy to save more, spend less and invest smarter. Top performing portfolios, matched to your style. We empower you with simple digital solutions to help you take control of your money. We’re helping even more Canadian millennials build real wealth.
                                <br />
                                <br />
                                Our unique service is made possible by combining a team of world-class financial experts. When you use our website and mobile applications you enter into a series of legally binding agreements. You also agree to our Privacy policy which covers how we collect, use, share and store your personal information.
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">1.2 Agreement</h3>
                              <p style={{textAlign: 'justify'}}>
                                When you access our website at www.piggyalpha.com and/or our mobile applications ("our website" (which includes our blog), "our app" or "PiggyAlpha") as a User, you're agreeing to be bound by the following Terms of Use. Please therefore take the time to read the following binding Terms of Use below.
                                <br />
                                <br />
                                For the purpose of this agreement a User is an individual who uses our web app or mobile application to evaluate our service, or for educational purposes that entitles the Client to have her or his investment portfolio managed by PiggyAlpha. This Agreement, as well as the Privacy policy applies to both Users and Clients.
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">2.1 Eligibility</h3>
                              <p style={{textAlign: 'justify'}}>
                                PiggyAlpha is intended solely for individuals who are 18 or older. Any access to or use of PiggyAlpha by anyone under 18 is unauthorized, unlicensed, and in violation of these Terms of Use. By accessing or using PiggyAlpha, you represent and warrant that you are 18 or older.
                                <br />
                                <br />
                                You will keep your user name and password a secret.<br />
                                You will not share your account with anyone else.
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">2.2 Your Access</h3>
                              <p style={{textAlign: 'justify'}}>
                                In order to access certain features of PiggyAlpha, you must register to create an account ("User Account"). When you register, you will be asked to choose a password, which you will be required to use to access your User Account. PiggyAlpha has physical, electronic and procedural safeguards that comply with federal standards to guard Users' and Clients' non-public personal information <span>see Privacy Policy</span>.
                                <br />
                                <br />
                                You are responsible for safeguarding your password and other User Account information. You agree not to disclose your password to any third party and you will notify PiggyAlpha immediately if your password is lost or stolen or if you suspect any unauthorized use of your User Account. As a User you agree that you shall be solely responsible for any activities or actions under your User Account, whether or not you have authorized such activities or actions.
                                <br />
                                <br />
                                You agree that the information you provide to us on account registration through our website or mobile applications will be true, accurate, current, and complete.
                                <br />
                                <br />
                                If you wish to develop enhancements to the PiggyAlpha service then you must do so via our "Partner Program" API. As used in these Terms, "APIs" means programmatic web APIs and associated tools and documentation that provide access to certain data displayed on our website or in our mobile applications. Access to our APIs requires you meet our Partner Program eligibility criteria and enter into a signed partnership agreement with PiggyAlpha. You may not scrape or copy information through any means (including crawlers, browser plugins and add-ons, and any other technology or manual work) as explained in Section 6.2.
                                <br />
                                <br />
                                If you wish to learn more about becoming a Partner, please contact support@PiggyAlpha.com.
                                We may send you communications that you are required to read. You may customize them according to your preferences.
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">2.3 Alerts, Notifications and Service Communications</h3>
                              <p style={{textAlign: 'justify'}}>
                                By creating a User Account, you automatically sign up for various types of alerts via e-mail and mobile notification. When logged in, you may customize, modify and in some cases deactivate alerts by adjusting the settings accordingly. We never include your password in these communications, but we may include your name, or email address and information about your portfolio(s) if you are a Client. Anyone with access to your e-mail or mobile device will be able to view these alerts. You may unsubscribe from marketing oriented emails at any time.
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">3. Disclaimer and Limit of Liability </h3>
                              <p style={{textAlign: 'justify'}}>
                                PiggyAlpha cannot be held responsible for any financial insights or recommendations provided to Users.
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">3.1 For a User who is not a Client</h3>
                              <p style={{textAlign: 'justify'}}>
                                You understand and acknowledge that the investment results you could obtain from investment information and financial insights provided by PiggyAlpha cannot be guaranteed and that PiggyAlpha cannot be held responsible. All investments entail a risk of loss and that you may lose money. Investment management services may be offered to individuals who become clients, at the sole discretion of PiggyAlpha.
                                <br />
                                <br />
                                You agree and understand that PiggyAlpha is only one of many tools you may use as part of a comprehensive investment education process, that you should not and will not rely on PiggyAlpha as the primary basis of your investment decisions and, except as otherwise provided for herein, PiggyAlpha will not be liable for decisions/actions you take or authorize third parties to take on your behalf based on information you receive as a User of PiggyAlpha or information you otherwise see on our website. This is our disclaimer of legal liability.
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">3.2 Disclaimer of Warranties</h3>
                              <p style={{textAlign: 'justify'}}>
                                Your use of PiggyAlpha's Website, and the personal information you provide is at your sole discretion and risk. PiggyAlpha's Website and all materials, information, products and services included therein, are provided on an AS IS and AS AVAILABLE basis without warranties of any kind from PiggyAlpha.
                                <br />
                                <br />
                                PiggyAlpha EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, RELATING TO PiggyAlpha'S WEBSITE, CONTENT AND/OR USER INFORMATION, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF PROPRIETARY RIGHTS, COURSE OF DEALING OR COURSE OF PERFORMANCE. PiggyAlpha DISCLAIMS ANY WARRANTIES, EXPRESS OR IMPLIED:
                              </p><ul>
                                <li>REGARDING THE AVAILABILITY, SECURITY, ACCURACY, RELIABILITY, TIMELINESS AND PERFORMANCE OF PiggyAlpha'S WEBSITE, CONTENT AND/OR USER INFORMATION;</li>
                                <li>THAT PiggyAlpha'S WEBSITE WILL BE ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED;</li>
                                <li>THAT PiggyAlpha'S WEBSITE WILL BE ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED;</li>
                                <li>THAT PiggyAlpha'S WEBSITE WILL BE FREE FROM ELECTRONIC VIRUSES; OR</li>
                                <li>REGARDING THE PERFORMANCE OF OR ACCURACY, QUALITY, CURRENCY, COMPLETENESS OR USEFULNESS OF ANY INFORMATION PROVIDED BY PiggyAlpha ON ITS WEBSITE INCLUDING BUT NOT LIMITED TO INFORMATION OBTAINED THROUGH SOCIAL MEDIA.</li>
                              </ul>
                              <br />
                              No advice or information, whether oral or written, obtained by you from PiggyAlpha's Website, shall create any warranty not expressly stated in this Agreement. If you choose to rely on such information, you do so solely at your own risk.
                              <br /><br />
                              Some states or jurisdictions do not allow the exclusion of certain warranties. Accordingly, some of the above exclusions may not apply to you. These are the limits of legal liability we may have to you.
                              <p />
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">3.3 Limitation of Liability</h3>
                              <p style={{textAlign: 'justify'}}>
                                IN NO EVENT SHALL PiggyAlpha OR ANY OF ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF PiggyAlpha, CONTENT AND/OR USER INFORMATION, INCLUDING BUT NOT LIMITED TO THE QUALITY, ACCURACY, OR UTILITY OF THE INFORMATION PROVIDED AS PART OF OR THROUGH PiggyAlpha OR FOR ANY INVESTMENT DECISIONS MADE ON THE BASIS OF SUCH INFORMATION, WHETHER THE DAMAGES ARE FORESEEABLE AND WHETHER OR NOT PiggyAlpha HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION AND IN NO EVENT SHALL PiggyAlpha'S CUMULATIVE LIABILITY TO YOU EXCEED U.S. $100.
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">4. Termination</h3>
                              <p style={{textAlign: 'justify'}}>
                                Each of us can end this agreement at any time.
                                You may request termination of your User Account at any time and for any reason by sending an email to support@PiggyAlpha.com.
                                <br />
                                <br />
                                We may terminate or suspend your access to PiggyAlpha, at our sole discretion, at any time for any reason without notice to you. Further, if we believe, in our sole discretion, that a violation of these Terms of Use has occurred, we may take any other corrective action we deem appropriate. We reserve the right to investigate suspected violations of these Terms of Use. We may seek to gather information from a user who is suspected of violating these Terms of Use (or from any other user) and you agree to provide us with such information. We will fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity of anyone posting, publishing, or otherwise making available any User information, emails, or other materials that are believed to violate these Terms of Use.
                                <br /><br />
                                Any suspension, termination, or cancellation shall not affect your obligations to PiggyAlpha under these Terms of Use (including but not limited to ownership, indemnification, and limitation of liability), which by their sense and context are intended to survive such suspension, termination, or cancellation.
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">5. Dispute Resolution</h3>
                              <p style={{textAlign: 'justify'}}>
                                If there is a legal dispute, it will take place in Canadian Courts under Canadian Law.
                                You agree that these Terms of Use shall be governed by and interpreted in accordance with the laws of Canada excluding that body of law pertaining to conflict of laws.
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">6. PiggyAlpha "Dos" and "Don'ts"</h3>
                              <p style={{textAlign: 'justify'}}>
                                PiggyAlpha grants you a license to use our website and mobile applications so long as you comply with all our rules.
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">6.1 Dos.</h3>
                              <p style={{textAlign: 'justify'}}>
                                You agree you will:
                              </p><ul>
                                <li>
                                  Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements;
                                </li>
                                <li>
                                  Provide accurate information to us, whether reported directly or through a third party who you authorize, and keep it updated;
                                </li>
                                <li>
                                  Use the services solely for your personal, non-commercial use;
                                </li>
                                <li>
                                  Use your real name on your profile and keep your password confidential;
                                </li>
                                <li>
                                  Use the Services in a professional manner
                                </li>
                              </ul>
                              When you access our website at www.piggyalpha.com and/or our mobile applications ("our website" (which includes our blog), "our app" or "PiggyAlpha") as a User, you're agreeing to be bound by the following Terms of Use. Please therefore take the time to read the following binding Terms of Use below.
                              <br />
                              <br />
                              For the purpose of this agreement a User is an individual who uses our web app or mobile application to evaluate our service, or for educational purposes that entitles the Client to have her or his investment portfolio managed by PiggyAlpha. This Agreement, as well as the Privacy policy applies to both Users and Clients.
                              <p />
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">6.2 Don'ts.</h3>
                              <p style={{textAlign: 'justify'}}>
                                You agree you would not:
                              </p><ul>
                                <li>
                                  Circumvent, disable, or otherwise interfere with security-related features of PiggyAlpha or features that prevent or restrict use or copying of any content or User information;
                                </li>
                                <li>
                                  Upload, email, transmit, provide, or otherwise make available:
                                  <ul>
                                    <li>any User information which you do not have the lawful right to use, copy, transmit, display, or make available (including any User information that would violate any confidentiality or fiduciary obligations that you might have with respect to the User information); or
                                    </li>
                                    <li>
                                      any User information that infringes the intellectual property rights of, or violates the privacy rights of, any third-party (including without limitation copyright, trademark, patent, trade secret, or other intellectual property right, moral right, or right of publicity); or
                                    </li>
                                    <li>
                                      unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of solicitation; or
                                    </li>
                                    <li>
                                      any personal information that is unlawful, obscene, harmful, threatening, harassing, defamatory, or hateful, or that contain objects or symbols of hate, invade the privacy of any third-party, contain nudity, are deceptive, threatening, abusive, inciting of unlawful action, or are otherwise objectionable in the sole discretion of PiggyAlpha; or
                                    </li>
                                    <li>
                                      any personal information that contains software viruses or any other computer code, files, or programs designed to (i) interrupt, destroy, or limit the functionality of any computer software; or (ii) interfere with the access of any user, host or network, including without limitation overloading, flooding, spamming, mail-bombing, or sending a virus to PiggyAlpha; or
                                    </li>
                                    <li>
                                      any personal information that includes code that is hidden or otherwise surreptitiously contained within the User information;
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  Use any meta tags or other hidden text or metadata utilizing a PiggyAlpha name, trademark, URL or product name;
                                </li>
                                <li>
                                  Forge any TCP/IP packet header or any part of the header information in any posting, or in any way use PiggyAlpha to send altered, deceptive, or false source- identifying information;
                                </li>
                                <li>
                                  Interfere with or disrupt (or attempt to interfere with or disrupt) any PiggyAlpha web page, server, or network, or the technical delivery systems of PiggyAlpha's providers, or disobey any requirements, procedures, policies, or regulations of networks connected to PiggyAlpha.
                                </li>
                                <li>
                                  Attempt to probe, scan, or test the vulnerability of any PiggyAlpha system or network or breach or impair or circumvent any security or authentication measures protecting PiggyAlpha;
                                </li>
                                <li>
                                  Attempt to decipher, decompile, disassemble, or reverse-engineer any of the software used to provide PiggyAlpha;
                                </li>
                                <li>
                                  Attempt to access, search, or meta-search PiggyAlpha or content thereon with any engine, software, tool, agent, device, or mechanism other than software and/or search agents provided by PiggyAlpha or other generally available third- party web browsers, including without limitation any software that sends queries to PiggyAlpha to determine how a website or web page ranks;
                                </li>
                                <li>
                                  Violate the terms of service or any other rule or agreement applicable to you or PiggyAlpha's inclusion in, reference to, or relationship with any third party or third-party site or service, or your use of any such third-party site or service;
                                </li>
                                <li>
                                  Forge any TCP/IP packet header or any part of the header information in any posting, or in any way use PiggyAlpha to send altered, deceptive, or false source- identifying information;
                                </li>
                                <li>
                                  Collect or store personal information about other users without their express permission;
                                </li>
                                <li>
                                  Impersonate or misrepresent your affiliation with any person or entity, through pretexting or some other form of social engineering, or commit fraud;
                                </li>
                                <li>
                                  Solicit any User for any investment or other commercial or promotional transaction;
                                </li>
                                <li>
                                  Violate any applicable law, regulation, or ordinance;
                                </li>
                                <li>
                                  Scrape or copy information through any means (including crawlers, browser plugins and add-ons, and any other technology or manual work);
                                </li>
                                <li>
                                  Use, launch, or permit to be used any automated system, including without limitation "robots," "crawlers," or "spiders"; or
                                </li>
                                <li>
                                  Copy or use the information, content or data on PiggyAlpha in connection with a competitive service (as determined by PiggyAlpha);
                                </li>
                                <li>
                                  Monitor PiggyAlpha's availability, performance or functionality for any competitive purposes;
                                </li>
                                <li>
                                  Use PiggyAlpha or content thereon in any manner not permitted by these Terms of Use;
                                </li>
                                <li>
                                  Copyrighted Materials: No Unauthorized Use. If you become aware of misuse of PiggyAlpha or content thereon by any person, please contact PiggyAlpha to report any abuse. PiggyAlpha has adopted and implemented a policy that provides for the termination of Accounts of users who infringe the rights of copyright holders. Please see the PiggyAlpha Copyright and Intellectual Property Policy for further information.
                                </li>
                              </ul>
                              <br />
                              <br />
                              <p />
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">7. Account Information from Third Party Sites</h3>
                              <p style={{textAlign: 'justify'}}>
                                Users may link their accounts with third-party financial institutions with which they have customer relationships, maintain accounts or engage in financial transactions and direct PiggyAlpha to retrieve and display information from these accounts (“Account Information”). PiggyAlpha works with one or more third-party service providers to access this Account Information. By linking these accounts or directing PiggyAlpha to retrieve your third-party Account Information, you are consenting to the processing of your Account Information, and other personal information you provide that is needed to retrieve your Account Information, such as credentials, by PiggyAlpha and such service provider(s) in connection with providing this service in accordance with our Privacy Policy and as permitted by law, and, if you’ve elected to become a Client, you are consenting to the use of your Account Information by PiggyAlpha and its affiliates in connection with the management of your PiggyAlpha account. PiggyAlpha does not review the Account Information for accuracy, legality or non-infringement. PiggyAlpha is not responsible for the Account Information or products and services offered by or on third-party sites.
                                <br />
                                <br />
                                PiggyAlpha cannot always foresee or anticipate technical or other difficulties which may result in failure to obtain data or loss of data, personalization settings or other service interruptions. PiggyAlpha cannot assume responsibility for the timeliness, accuracy, deletion, non-delivery or failure to store any user data, communications or personalization settings. Displayed Account Information is only as fresh as the time shown, which reflects when the information was obtained from third-party sources.
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">8. General Terms</h3>
                              <p style={{textAlign: 'justify'}}>
                                Some other important details about the Agreement
                                In the event that any provision in these Terms of Use is held to be invalid or unenforceable, the remaining provisions will remain in full force and effect. The failure of a party to enforce any right or provision of these Terms of Use will not be deemed a waiver of such right or provision. You may not assign this Agreement (by operation of law or otherwise) without the prior written consent of PiggyAlpha, and any prohibited assignment will be null and void.
                                <br />
                                <br />
                                PiggyAlpha may assign this Terms of Use Agreement or any rights hereunder without your consent. The relationship of the parties under these Terms of Use is that of independent contractors, and these Terms of Use will not be construed to imply that either party is the agent, employee, or joint venture of the other. Note that if you elect to become a Client, the relationship of the parties will be governed by these Terms of Use our Privacy Policy and any additional terms to which you agree when you create and fund an investment account.
                                <br /><br />
                                We reserve the right to change this Agreement by posting a revised Terms of Use and we agree that changes cannot be retroactive. If you don't agree with these changes, you must stop using PiggyAlpha.
                                <br /><br />
                                You agree the only way to provide us legal notice is at the address provided in Section 10.5.
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">9. International Use</h3>
                              <p style={{textAlign: 'justify'}}>
                                PiggyAlpha is only available for use in Canada. We make no representation that PiggyAlpha is appropriate or available for use outside Canada. Similarly, we make no representations that accessing PiggyAlpha from locations outside Canada is legal or permissible by local law. If you access PiggyAlpha from areas outside Canada, you do so at your own risk and are yourself responsible for compliance with local laws.
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">10. Miscellaneous</h3>
                              <p style={{textAlign: 'justify'}}>
                                This Agreement takes priority over previous agreements
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">10.1 Integration &amp; Severability</h3>
                              <p style={{textAlign: 'justify'}}>
                                This Agreement is the entire agreement between you and us with respect to PiggyAlpha, and your use of our website and mobile app, and supersedes all prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and us. If any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder.
                                <br />
                                <br />
                                Investment recommendations are made to Clients on an individual basis and may differ based on specific circumstances.
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">10.2 Advice may differ</h3>
                              <p style={{textAlign: 'justify'}}>
                                You understand and agree that we act as an investment advisor for Clients and will continue to do so. We and our personnel may make recommendations and take action for Clients that may differ from the recommendations we give to you or the action we take on your behalf.
                                In addition, our personnel may take actions for their own accounts based on their own investment situations that differ from the recommendations we give you or actions we take on your behalf.
                                Any notice or communication will be in writing by email.
                                When you access our website at www.piggyalpha.com and/or our mobile applications ("our website" (which includes our blog), "our app" or "PiggyAlpha") as a User, you're agreeing to be bound by the following Terms of Use. Please therefore take the time to read the following binding Terms of Use below.
                                <br />
                                <br />
                                For the purpose of this agreement a User is an individual who uses our web app or mobile application to evaluate our service, or for educational purposes that entitles the Client to have her or his investment portfolio managed by PiggyAlpha. This Agreement, as well as the Privacy policy applies to both Users and Clients.
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">10.3 Communication</h3>
                              <p style={{textAlign: 'justify'}}>
                                You agree that these Terms of Use and the rules, restrictions, and policies contained herein, and PiggyAlpha's enforcement thereof, are not intended to confer and do not confer any rights or remedies on any person other than you and PiggyAlpha. These Terms of Use together with the PiggyAlpha's Privacy Policy and Client Agreement (if applicable) constitute the entire agreement between PiggyAlpha and you with respect to the subject matter hereof. Any notice or other communication to be given hereunder will be in writing and given by (a) PiggyAlpha via email (in each case to the address that you provide), or (b) you via email to support@PiggyAlpha.com or to such other addresses as PiggyAlpha may specify in writing. The date of receipt shall be deemed the date on which such notice is transmitted.
                                <br />
                                <br />
                                We welcome your feedback!
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">10.4 Feedback</h3>
                              <p style={{textAlign: 'justify'}}>
                                Your feedback is welcome and encouraged. You may submit feedback by emailing us at support@PiggyAlpha.com. You agree, however, that (i) by submitting unsolicited ideas to PiggyAlpha or any of its employees or representatives, by any medium, including but not limited to email, written, or oral communication, you automatically forfeit your right to any intellectual property rights in such ideas; and (ii) such unsolicited ideas automatically become the property of PiggyAlpha. You hereby assign and agree to assign all rights, title, and interest you have in such feedback and ideas to PiggyAlpha together with all intellectual property rights therein. In addition, you warrant that all moral rights in any feedback have been waived, and you do hereby waive any such moral rights.
                                <br />
                                <br />
                                If you have any Questions..
                              </p>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">10.5 Questions</h3>
                              <p style={{textAlign: 'justify'}}>
                                This document constitutes PiggyAlpha's complete Terms of Use for PiggyAlpha and related services.
                                If you have questions about these Terms of Use or about PiggyAlpha or content thereon, please contact PiggyAlpha at support@PiggyAlpha.com.
                                <br />
                                <br />
                                You can also contact PiggyAlpha by phone at +16479245191
                              </p>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="support-tab-2" role="tabpanel">
                      <div className="support-article-wrap">
                        <h2>Payments Query</h2>
                        <ul className="support-article-list list-unstyled">
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Do you offer refunds for the subscriptions?</h3>
                              <p>Credibly evisculate mission-critical with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What is the monthly cost of your app?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices ...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Do you have any local branches?</h3>
                              <p>Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices standardized...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What do I need to create an account?</h3>
                              <p> Intrinsicly evisculate e-business best practices productivate standardized convergence. Credibly evisculate mission-critical ...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Are you open for new podcast guests?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative infrastructures with high standards in e-markets...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">When is the upcoming annual event?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative e-business best practices productivate standardized...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What is the monthly cost of your app?</h3>
                              <p>Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What’s inside the Facebook community?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="support-tab-3" role="tabpanel">
                      <div className="support-article-wrap">
                        <h2>Setup or Installment</h2>
                        <ul className="support-article-list list-unstyled">
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Do you offer refunds for the subscriptions?</h3>
                              <p>Credibly evisculate mission-critical with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What is the monthly cost of your app?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices ...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Do you have any local branches?</h3>
                              <p>Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices standardized...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">When is the upcoming annual event?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative e-business best practices productivate standardized...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What do I need to create an account?</h3>
                              <p> Intrinsicly evisculate e-business best practices productivate standardized convergence. Credibly evisculate mission-critical ...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Are you open for new podcast guests?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative infrastructures with high standards in e-markets...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What is the monthly cost of your app?</h3>
                              <p>Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What’s inside the Facebook community?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="support-tab-4" role="tabpanel">
                      <div className="support-article-wrap">
                        <h2>Technical Support</h2>
                        <ul className="support-article-list list-unstyled">
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Do you have any local branches?</h3>
                              <p>Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices standardized...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What do I need to create an account?</h3>
                              <p> Intrinsicly evisculate e-business best practices productivate standardized convergence. Credibly evisculate mission-critical ...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Do you offer refunds for the subscriptions?</h3>
                              <p>Credibly evisculate mission-critical with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What is the monthly cost of your app?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices ...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Are you open for new podcast guests?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative infrastructures with high standards in e-markets...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">When is the upcoming annual event?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative e-business best practices productivate standardized...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What is the monthly cost of your app?</h3>
                              <p>Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What’s inside the Facebook community?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="support-tab-5" role="tabpanel">
                      <div className="support-article-wrap">
                        <h2>Retailers &amp; Customer</h2>
                        <ul className="support-article-list list-unstyled">
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Do you offer refunds for the subscriptions?</h3>
                              <p>Credibly evisculate mission-critical with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What is the monthly cost of your app?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices ...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Do you have any local branches?</h3>
                              <p>Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices standardized...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What do I need to create an account?</h3>
                              <p> Intrinsicly evisculate e-business best practices productivate standardized convergence. Credibly evisculate mission-critical ...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Are you open for new podcast guests?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative infrastructures with high standards in e-markets...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">When is the upcoming annual event?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative e-business best practices productivate standardized...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What is the monthly cost of your app?</h3>
                              <p>Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What’s inside the Facebook community?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="support-tab-6" role="tabpanel">
                      <div className="support-article-wrap">
                        <h2>Security Issues</h2>
                        <ul className="support-article-list list-unstyled">
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Do you offer refunds for the subscriptions?</h3>
                              <p>Credibly evisculate mission-critical with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What is the monthly cost of your app?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices ...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Do you have any local branches?</h3>
                              <p>Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices standardized...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">When is the upcoming annual event?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative e-business best practices productivate standardized...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What do I need to create an account?</h3>
                              <p> Intrinsicly evisculate e-business best practices productivate standardized convergence. Credibly evisculate mission-critical ...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Are you open for new podcast guests?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative infrastructures with high standards in e-markets...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What is the monthly cost of your app?</h3>
                              <p>Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What’s inside the Facebook community?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="support-tab-7" role="tabpanel">
                      <div className="support-article-wrap">
                        <h2>Brand Creation</h2>
                        <ul className="support-article-list list-unstyled">
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Do you have any local branches?</h3>
                              <p>Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices standardized...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What do I need to create an account?</h3>
                              <p> Intrinsicly evisculate e-business best practices productivate standardized convergence. Credibly evisculate mission-critical ...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Do you offer refunds for the subscriptions?</h3>
                              <p>Credibly evisculate mission-critical with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What is the monthly cost of your app?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices ...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Are you open for new podcast guests?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative infrastructures with high standards in e-markets...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">When is the upcoming annual event?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative e-business best practices productivate standardized...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What is the monthly cost of your app?</h3>
                              <p>Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What’s inside the Facebook community?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="support-tab-8" role="tabpanel">
                      <div className="support-article-wrap">
                        <h2>Legal Support</h2>
                        <ul className="support-article-list list-unstyled">
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Do you have any local branches?</h3>
                              <p>Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices standardized...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What do I need to create an account?</h3>
                              <p> Intrinsicly evisculate e-business best practices productivate standardized convergence. Credibly evisculate mission-critical ...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Do you offer refunds for the subscriptions?</h3>
                              <p>Credibly evisculate mission-critical with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What is the monthly cost of your app?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices ...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">Are you open for new podcast guests?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative infrastructures with high standards in e-markets...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">When is the upcoming annual event?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative e-business best practices productivate standardized...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What is the monthly cost of your app?</h3>
                              <p>Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                          <li className="py-4 border-top border-light">
                            <a  className="text-decoration-none d-block text-muted">
                              <h3 className="h5 support-article-title">What’s inside the Facebook community?</h3>
                              <p>Credibly evisculate mission-critical products before quality e-services. Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                              <span className="btn-link text-decoration-none read-more-link">Read More <i className="far fa-arrow-right ms-2" /></span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer className="footer-section" id="footer">
            <div className="footer-top footer-light ptb-120">
              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-md-8 col-lg-5 mb-md-4 mb-lg-0">
                    <div className="footer-single-col">
                      <div className="footer-single-col mb-4">
                        <img src="assets/img/logo-white.png" alt="logo" className="img-fluid logo-white" />
                        <img src="assets/img/logo-color.png" alt="logo" className="img-fluid logo-color" />
                      </div>
                      <p>Our latest news, articles, and resources, we will sent to
                        your inbox weekly.</p>
                      <form className="newsletter-form position-relative d-block d-lg-flex d-md-flex">
                        <input type="text" className="input-newsletter form-control me-2" placeholder="Enter your email" name="email" required autoComplete="off" />
                        <input type="submit" defaultValue="Subscribe" data-wait="Please wait..." className="btn btn-primary mt-3 mt-lg-0 mt-md-0" />
                      </form>
                      <div className="ratting-wrap mt-4">
                        <h6 className="mb-0">10/10 Overall rating</h6>
                        <ul className="list-unstyled rating-list list-inline mb-0">
                          <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                          <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                          <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                          <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                          <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-5 mt-5 mt-md-0 mt-lg-0">
                    <div className="row">
                      <div className="col-md-4 col-lg-5 mt-4 mt-md-0 mt-lg-0">
                        <div className="footer-single-col">
                          <h3>Pages</h3>
                          <ul className="list-unstyled footer-nav-list mb-lg-0">
                            <li><a href="/" className="text-decoration-none">Home</a></li>
                            <li><a href="about" className="text-decoration-none">about</a></li>
                            <li><a href="services" className="text-decoration-none">Invest</a></li>
                            <li><a href="blog" className="text-decoration-none">Blog</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-5 mt-5 mt-md-0 mt-lg-0">
                        <div className="footer-single-col">
                          <h3>Legal</h3>
                          <ul className="list-unstyled footer-nav-list mb-lg-0">
                            <li><a href="terms" className="text-decoration-none">Terms of Service</a></li>
                            <li><a href="privacy" className="text-decoration-none">Privacy Policy</a></li>
                          
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom footer-light py-4">
              <div className="container">
                <div className="row justify-content-between align-items-center">
                  <div className="col-md-7 col-lg-7">
                    <div className="copyright-text">
                      <p className="mb-lg-0 mb-md-0">2021 PiggyAlpha. </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4">
                    <div className="footer-single-col text-start text-lg-end text-md-end">
                      <ul className="list-unstyled list-inline footer-social-list mb-0">
                        <li className="list-inline-item"><a href="0#"><i className="fab fa-facebook-f" /></a></li>
                        <li className="list-inline-item"><a href="0#"><i className="fab fa-instagram" /></a></li>
                        <li className="list-inline-item"><a href="0#"><i className="fab fa-dribbble" /></a></li>
                        <li className="list-inline-item"><a href="0#"><i className="fab fa-github" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
    </div>
            
      );
    }
  };

export default Terms;
