import React from 'react';


class Privacy extends React.Component{
    render () {
      return (
      <div>
        <div className="main-wrapper">
          <header className="main-header position-absolute w-100">
            <nav className="navbar navbar-expand-xl navbar-dark sticky-header">
              <div className="container d-flex align-items-center justify-content-lg-between position-relative">
                <a href="/" className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none">
                  Piggy Alpha
                  <img src="assets/img/logo-color.png" alt="logo" className="img-fluid logo-color" />
                </a>
                <a className="navbar-toggler position-absolute right-0 border-0 " href="#offcanvasWithBackdrop" role="button">
                  <span className="far fa-bars" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop" />
                </a>
                <div className="clearfix" />
                <div className="collapse navbar-collapse justify-content-center">
                  <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                    <li className="nav-item dropdown">
                      <a className="nav-link active" href="/" role="button" aria-expanded="false">
                        Home
                      </a>
                    </li>
                    <li><a href="about" className="nav-link">About</a></li>
                    <li><a href="invest" className="nav-link">Invest</a></li>
                    <li><a href="blog" className="nav-link">Blog</a></li>
                  </ul>
                </div>
                <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
                  <a href="https://admin.alphawealth.io" className="btn btn-link text-decoration-none me-2">Sign In</a>
                  <a href="https://admin.alphawealth.io" className="btn btn-primary">Get Started</a>
                </div>
                <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasWithBackdrop">
                  <div className="offcanvas-header d-flex align-items-center mt-4">
                    <a href="/" className="d-flex align-items-center mb-md-0 text-decoration-none">
                      <img src="assets/img/logo-color.png" alt="logo" className="img-fluid ps-2" />
                    </a>
                    <button type="button" className="close-btn text-danger" data-bs-dismiss="offcanvas" aria-label="Close"><i className="far fa-close" /></button>
                  </div>
                  <div className="offcanvas-body">
                    <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                      <li className="nav-item dropdown">
                        <a className="nav-link " href="0#" role="button" aria-expanded="false">
                          Home
                        </a>
                      </li>
                      <li><a href="about" className="nav-link">about</a></li>
                      <li><a href="invest" className="nav-link">Invest</a></li>
                      <li><a href="blog" className="nav-link">Blog</a></li>
                      <li><a href="services" className="nav-link">Contact</a></li>
                    </ul>
                    <div className="action-btns mt-4 ps-3">
                      <a href="https://admin.alphawealth.io" className="btn btn-outline-primary me-2">Sign In</a>
                      <a href="https://admin.alphawealth.io" className="btn btn-primary">Get Started</a>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </header>
          <section className="page-header overflow-hidden ptb-120 bg-dark" style={{background: 'url("assets/img/page-header-bg.svg")no-repeat bottom left'}}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 col-md-12">
                  <div className="text-center">
                    <h1 className="display-5 fw-bold">Privacy Policy</h1>
                    <div className="form-block-banner mw-60 m-auto mt-5">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
       
      <section className="support-content ptb-120">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-12 col-md-8">
              <div className="tab-content" id="v-pills-supportContent">
                <div className="tab-pane fade show active" id="support-tab-1" role="tabpanel">
                  <div className="support-article-wrap" style={{textAlign: 'justify'}}>
                    <h4>Piggy Alpha Privacy Policy</h4>
                    <p>Policy Effective Date: November 22nd, 2021</p>
                    <ul className="support-article-list list-unstyled mt-4">
                      <li className="py-4 border-top border-light">
                        <a className="text-decoration-none d-block text-muted">
                          <h3 className="h5 support-article-title">1. What Data We Get</h3>
                          <p style={{textAlign: 'justify'}}>
                            We collect certain data from you directly like information you enter yourself data about your participation with PiggyAlpha and data from third-party platforms you connect with us. We also collect some data automatically information about your device and what parts of our Services you interact with or spend time using.
                          </p>
                        </a>
                      </li>
                      <li className="py-4 border-top border-light">
                        <a className="text-decoration-none d-block text-muted">
                          <h3 className="h5 support-article-title">2. Data You Provide to Us</h3>
                          <p style={{textAlign: 'justify'}}>
                            We may collect different data from or about you depending on how you use our Services.
                          </p>
                        </a>
                      </li>
                      <li className="py-4 border-top border-light">
                        <a className="text-decoration-none d-block text-muted">
                          <h3 className="h5 support-article-title">3. How We Get Data About You</h3>
                          <p style={{textAlign: 'justify'}}>
                            We use tools like cookies, web beacons, analytics services, and advertising providers to gather the data listed above. Some of these tools offer you the ability to opt out of data collection.
                          </p>
                        </a>
                      </li>
                      <li className="py-4 border-top border-light">
                        <a className="text-decoration-none d-block text-muted">
                          <h3 className="h5 support-article-title">4. What We Use Your Data For</h3>
                          <p style={{textAlign: 'justify'}}>
                          </p><ul>
                            <li>
                              Responding to your questions and concerns;
                            </li>
                            <li>
                              Sending you administrative messages and information, including messages from instructors and teaching assistants, notifications about changes to our Service, and updates to our agreements;
                            </li>
                            <li>
                              Sending push notifications to your wireless device to provide updates and other relevant messages (which you can manage from the “options” or “settings” page of the mobile app);
                            </li>
                          </ul>
                          <p />
                        </a>
                      </li>
                      <li className="py-4 border-top border-light">
                        <a className="text-decoration-none d-block text-muted">
                          <h3 className="h5 support-article-title">5. Your Choices About the Use of Your Data</h3>
                          <p style={{textAlign: 'justify'}}>
                            You can choose not to provide certain data to us, but you may not be able to use certain features of the Services.
                          </p><ul>
                            <li>
                              To stop receiving promotional communications from us, you can opt out by using the unsubscribe mechanism in the promotional communication you receive or by changing the email preferences in your account. Note that regardless of your email preference settings, we will send you transactional and relationship messages regarding the Services, including administrative confirmations, order confirmations, important updates about the Services, and notices about our policies.
                            </li>
                            <li>
                              The browser or device you use may allow you to control cookies and other types of local data storage. Your wireless device may also allow you to control whether location or other data is collected and shared. You can manage Adobe’s LSOs through their Website Storage Settings panel.
                              Sending you administrative messages and information, including messages from instructors and teaching assistants, notifications about changes to our Service, and updates to our agreements;
                            </li>
                            <li>
                              To get information and control cookies used for tailored advertising from participating companies, see the consumer opt-out pages for the Network Advertising Initiative and Digital Advertising Alliance, or if you’re located in the European Union, visit the Your Online Choices site. To opt out of Google’s display advertising or customize Google Display Network ads, visit the Google Ads Settings page. To opt out of Taboola’s targeted ads, see the Opt-out Link in their Cookie Policy.
                            </li>
                            <li>
                              To update data you provide directly, log into your account and update your account at any time.
                            </li>
                          </ul>
                          <p />
                        </a>
                      </li>
                      <li className="py-4 border-top border-light">
                        <a className="text-decoration-none d-block text-muted">
                          <h3 className="h5 support-article-title">6. Our Policy Concerning Children</h3>
                          <p style={{textAlign: 'justify'}}>
                            We recognize the privacy interests of children and encourage parents and guardians to take an active role in their children’s online activities and interests. Children under 13 (or under 16 in the European Economic Area) should not use the Services. If we learn that we’ve collected personal data from a child under those ages.
                          </p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

          <footer className="footer-section" id="footer">
            <div className="footer-top footer-light ptb-120">
              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-md-8 col-lg-5 mb-md-4 mb-lg-0">
                    <div className="footer-single-col">
                      <div className="footer-single-col mb-4">
                        <img src="assets/img/logo-white.png" alt="logo" className="img-fluid logo-white" />
                        <img src="assets/img/logo-color.png" alt="logo" className="img-fluid logo-color" />
                      </div>
                      <p>Our latest news, articles, and resources, we will sent to
                        your inbox weekly.</p>
                      <form className="newsletter-form position-relative d-block d-lg-flex d-md-flex">
                        <input type="text" className="input-newsletter form-control me-2" placeholder="Enter your email" name="email" required autoComplete="off" />
                        <input type="submit" defaultValue="Subscribe" data-wait="Please wait..." className="btn btn-primary mt-3 mt-lg-0 mt-md-0" />
                      </form>
                      <div className="ratting-wrap mt-4">
                        <h6 className="mb-0">10/10 Overall rating</h6>
                        <ul className="list-unstyled rating-list list-inline mb-0">
                          <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                          <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                          <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                          <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                          <li className="list-inline-item"><i className="fas fa-star text-warning" /></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-5 mt-5 mt-md-0 mt-lg-0">
                    <div className="row">
                      <div className="col-md-4 col-lg-5 mt-4 mt-md-0 mt-lg-0">
                        <div className="footer-single-col">
                          <h3>Pages</h3>
                          <ul className="list-unstyled footer-nav-list mb-lg-0">
                            <li><a href="/" className="text-decoration-none">Home</a></li>
                            <li><a href="about" className="text-decoration-none">about</a></li>
                            <li><a href="services" className="text-decoration-none">Invest</a></li>
                            <li><a href="blog" className="text-decoration-none">Blog</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-5 mt-5 mt-md-0 mt-lg-0">
                        <div className="footer-single-col">
                          <h3>Legal</h3>
                          <ul className="list-unstyled footer-nav-list mb-lg-0">
                            <li><a href="terms" className="text-decoration-none">Terms of Service</a></li>
                            <li><a href="privacy" className="text-decoration-none">Privacy Policy</a></li>
                          
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom footer-light py-4">
              <div className="container">
                <div className="row justify-content-between align-items-center">
                  <div className="col-md-7 col-lg-7">
                    <div className="copyright-text">
                      <p className="mb-lg-0 mb-md-0">2021 PiggyAlpha. </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4">
                    <div className="footer-single-col text-start text-lg-end text-md-end">
                      <ul className="list-unstyled list-inline footer-social-list mb-0">
                        <li className="list-inline-item"><a href="0#"><i className="fab fa-facebook-f" /></a></li>
                        <li className="list-inline-item"><a href="0#"><i className="fab fa-instagram" /></a></li>
                        <li className="list-inline-item"><a href="0#"><i className="fab fa-dribbble" /></a></li>
                        <li className="list-inline-item"><a href="0#"><i className="fab fa-github" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
    </div>
            
      );
    }
  };

export default Privacy;
